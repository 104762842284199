import Image from "next/image";

export default function Logo(props) {
    let image;
    if (props.icon) {
        // image = "believemy-excitation.svg";
        image = "icon.svg";
        // let image = "believemyoctobreprimary.svg";

        if (props.white) {
            image = "icon-white.svg";
            // image = "believemyoctobrewhite.svg";
        }

        if (props.purple) {
            image = "icon.svg";
            // image = "believemyoctobrewhite.svg";
        }

        if (props.excitation) {
            image = "icon.svg";
        }
    } else {
        // image = "believemy-row-purple.svg";
        // image = "believemy-newblack.png";
        // image = "believemy-new-logo-black.svg";
        image = "believemy-new-logo-full-black.svg";
        // let image = "believemyoctobreprimary.svg";

        if (props.white) {
            // image = "believemy-row-white.svg";
            // image = "believemy-new-logo-white.svg";
            // image = "believemyoctobrewhite.svg";
            image = "believemy-new-logo-full-white.svg";
        }

        if (props.purple) {
            image = "believemy-row-purple.svg";
        }
    }

    return (
        <span style={{ lineHeight: 0 }} className={props.className}>
            {!props.icon ? (
                <Image
                    src={`/images/site/brand/${image}`}
                    alt="Believemy logo purple"
                    width={150 * (props.ratio || 1)}
                    height={30 * (props.ratio || 1)}
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                    }}
                    priority={props.priority || false}
                />
            ) : (
                <Image
                    src={`/images/site/brand/${image}`}
                    alt="Believemy logo purple"
                    width={30 * (props.ratio || 1)}
                    height={40.35 * (props.ratio || 1)}
                    style={{
                        maxWidth: "100%",
                        height: "auto",
                    }}
                    priority={props.priority || false}
                />
            )}
        </span>
    );
}
