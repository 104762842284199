import Image from "next/image";

export default function Icon(props) {
    let image = "believemy-icon-black.svg";
    // let image = "believemyoctobreprimary.svg";

    if (props.white) {
        image = "believemyfirewhite.svg";
        // image = "believemyoctobrewhite.svg";
    }

    if (props.outline) {
        if (props.white) {
            image = "believemyfirewhiteoutline.svg";
        } else {
            image = "believemyfireoutline.svg";
        }
    }

    return (
        <span style={{ lineHeight: 0 }}>
            <Image
                src={`/images/site/brand/${image}`}
                alt="Believemy logo"
                width={1000 * (props.ratio || 1)}
                height={1345 * (props.ratio || 1)}
                style={{
                    maxWidth: "100%",
                    height: "auto",
                }}
                priority={props.priority || false}
            />
        </span>
    );
}
