"use client";

// Libraries
import { useSession } from "next-auth/react";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/fr";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

// Components
import { usePathname, useRouter } from "next/navigation";
import Layout from "../Layout/Layout";
import Loading from "./InterfaceLayout/Loading/Loading";
import NewHeader from "../Header/NewHeader/NewHeader";
import { SpinnerCircular } from "spinners-react";
import Footer from "../Footer/Footer";

export default function UserLayout({ currentPage: page, ...props }) {
    // Variables
    const { data: session, status } = useSession();
    const loading = status === "loading";
    const router = useRouter();
    const pathname = usePathname();
    const cookies = new Cookies();

    moment.locale("fr");

    // States
    const [notifications, setNotifications] = useState([]);
    const [notificationsAreLoading, setNotificationsAreLoading] =
        useState(false);
    const [notificationIsDeleting, setNotificationIsDeleting] = useState(false);
    const [resendIsLoading, setResendIsLoading] = useState(false);
    const [resendEmailIsSended, setResendEmailIsSended] = useState(false);
    const [sendib, setSendIB] = useState(false);
    const [close, setClose] = useState(
        cookies &&
            cookies.get("closeMail") &&
            cookies.get("closeMail") == "true"
            ? true
            : false,
    );

    // Methods
    const loadNotifications = async (markAsRead = false) => {
        if (!notificationsAreLoading) {
            setNotificationsAreLoading(true);
            const response = await fetch("/api/user/get/notifications", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ markAsRead }),
            });

            const fetchedData = await response.json();

            if (!response.ok) {
                setNotificationsAreLoading(false);
                toast.error(fetchedData.message);
            } else {
                setNotificationsAreLoading(false);
                setNotifications(fetchedData.notifications);

                if (markAsRead) {
                    const event = new Event("visibilitychange");
                    document.dispatchEvent(event);
                }
            }
        }
    };
    const handleResendEmailConfirmationClicked = async () => {
        if (!resendIsLoading) {
            setResendIsLoading(true);
            const response = await fetch(
                "/api/user/resend-email-confirmation",
                {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            const fetchedData = await response.json();

            if (!response.ok) {
                setResendIsLoading(false);
                toast.error(fetchedData.message);
            } else {
                setResendIsLoading(false);
                toast.success("Email de confirmation envoyé.");
                setResendEmailIsSended(true);
            }
        }
    };
    const closeMail = () => {
        setClose(true);
        let date = new Date();
        date.setTime(date.getTime() + 60 * 1000 * 60);
        cookies.set("closeMail", "true", { path: "/", expires: date });
        toast.success(
            "Fenêtre fermée temporairement. Vérifiez votre adresse email pour continuer à utiliser Believemy.",
        );
    };

    useEffect(() => {
        if (!props.onlyUnconnected) {
            loadNotifications();
        }
    }, []);

    useEffect(() => {
        if (session && session.user) {
            setSendIB(true);
            if (!session.user.hasVerifiedEmail) {
                router.push("/verify-account");
                if (cookies && cookies.get("closeMail")) {
                    setClose(true);
                }
                return;
            }
        }
    }, [session]);

    if (props.needAuth && !session && !loading) {
        router.push("/");
        return null;
    }

    if (!session && !loading && !props.needAuth) {
        return (
            <Layout>
                <Loading />
                {props.children}
            </Layout>
        );
    }

    if (loading && props.needAuth && pathname.includes("/u/")) {
        return <></>;
    }

    if (
        loading &&
        props.needAuth &&
        pathname.includes("/community/discussion/")
    ) {
        return <></>;
    }

    if (session && !loading && session.user && !session.user.isOnboarded) {
        // If URL != /pricing
        if (
            !pathname.includes("/pricing") &&
            !pathname.includes("/onboarding") &&
            !pathname.includes("/verify-account")
        ) {
            router.replace("onboarding");
        }
    }

    return (
        <>
            {session && session.user && session.user.hasVerifiedEmail && (
                <>
                    {session &&
                        session.user &&
                        !session.user.hasVerifiedEmail &&
                        !close && (
                            <div className="bg-primary text-white text-center p-5 pb-2 fixed lg:bottom-5 bottom-0 lg:right-5 right-0 z-50 bg-opacity-90 lg:rounded-xl lg:w-96 cursor-pointer">
                                <div
                                    className="hover:bg-red-800 bg-primary bg-opacity-90 rounded-full p-1 transform -translate-x-1/2 absolute left-1/2 top-0 -translate-y-1/2"
                                    onClick={closeMail}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="w-8 h-8"
                                    >
                                        <g
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        >
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                            ></circle>
                                            <path
                                                strokeLinecap="round"
                                                d="m14.5 9.5l-5 5m0-5l5 5"
                                            ></path>
                                        </g>
                                    </svg>
                                </div>
                                <b>Vous devez confirmer votre adresse email</b>{" "}
                                grâce au mail que nous vous avons envoyé. Vous
                                êtes en train de profiter d'une version{" "}
                                <b>restreinte</b> de Believemy.
                                <br />
                                {resendEmailIsSended ? (
                                    <div className="text-center">
                                        Un nouveau mail vient de vous être
                                        envoyé.
                                    </div>
                                ) : resendIsLoading ? (
                                    <div className="flex justify-center">
                                        <SpinnerCircular
                                            size="30"
                                            thickness={150}
                                            color="white"
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="text-white font-bold inline-block cursor-pointer hover:underline"
                                        onClick={
                                            handleResendEmailConfirmationClicked
                                        }
                                    >
                                        <div className="flex items-center justify-center gap-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-6 w-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                                />
                                            </svg>
                                            <div>Réenvoyez-moi cet email</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    <div
                        className={`font-roboto relative ${
                            session &&
                            session.user &&
                            session.user.darkMode &&
                            "dark"
                        }`}
                    >
                        <div className="bg-light dark:bg-bgDark-700 transition-all duration-150">
                            {!props.withoutHeader && (
                                <div
                                    style={{
                                        height: props.withoutMarginForHead
                                            ? 0
                                            : 90,
                                    }}
                                >
                                    <NewHeader
                                        page={page}
                                        session={session}
                                        unstickyHeader={props.unstickyHeader}
                                        transparentHeader
                                    />
                                </div>
                            )}
                            {props.children}
                        </div>

                        {props.showFooter && <Footer withoutMarginTop />}
                    </div>
                </>
            )}
        </>
    );
}
